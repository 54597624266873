:root {
  --grad: linear-gradient(
    90deg,
    rgba(77, 23, 105, 1) 0%,
    rgba(77, 28, 171, 1) 35%,
    rgba(212, 86, 207, 1) 59%,
    rgba(146, 237, 255, 1) 100%
  );
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--grad);
  background-size: 350%;
  animation: bg-ani 60s infinite alternate;
}


@keyframes bg-ani {
  0% {
      background-position: left;
  }
  100% {
      background-position: right;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
